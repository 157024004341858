@import "defines";

.chops-icon {
  width: 120px;
  height: 120px;
  border-radius: 20px;
}

.tiny-link {
  font-size: 12px;
}
