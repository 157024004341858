@import "defines";

.contact-form {
    width: 100%;
}

.contact-form textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 8px;
}

.contact-form input {
    width: 300px;
    margin-bottom: 8px;
}
  
@media screen and (max-width: $phone-width) {
    .contact-form input {
        width: 100%;
    }
}